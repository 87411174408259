import axios from 'axios';
import { mapState } from 'vuex';
import _ from 'lodash';
import { formatToHuman } from '@/utils/time';
import helpers from '@/utils/helpers';

export default {
  data() {
    return {
      proposals: [],
      search: '',
      loadingTable: true,
      headers: [
        { text: 'Client Name', value: 'activePolClientName' },
        { text: 'Client Phone number', value: 'activePolClientTel' },
        { text: 'Client Email', value: 'activePolClientEmail' },
        { text: 'Products', value: 'activePolProduct' },
        { text: 'Sum Assured', value: 'activePolSA' },
        { text: 'Premium', value: 'activePolPremium' },
        { text: 'Date', value: 'activePolEffectiveDate' },
      ],
      pagination: {
        pageNumber: 1,
        perPage: 10,
        totalItems: 0,
        pageCount: 0,
      },
    };
  },
  watch: {
    'pagination.perPage': function () {
      this.getProposals();
    },

    'pagination.pageNumber': function () {
      this.getProposals();
    },

    search() {
      this.initiateSearch();
    },

  },
  computed: {
    ...mapState({
      agentCodeState: (state) => state.auth.userInfo.agnCode,
    }),
  },
  mounted() {
    this.getProposals();
    this.getIdNo();
  },
  methods: {
    getIdNo() {
      if (this.policyType === 'policy') {
        this.headers.unshift({
          text: 'Policy Number',
          align: 'start',
          sortable: false,
          value: 'activePolicyNo',
        });
      } else {
        this.headers.unshift({
          text: 'Proposal Number',
          align: 'start',
          sortable: false,
          value: 'activeProposalNo',
        });
      }
    },
    getProposals() {
      axios
        .get(
          `/lmsagent/quotations/findAgentPolicies?agentCode=${this.agentCodeState}&policyType=${this.policyType}&pageNo=${this.pagination.pageNumber}&pageSize=${this.pagination.perPage}
          &search=${this.search}`,
        )
        .then((res) => {
          this.proposals = res.data.data.content;
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },
    initiateSearch: _.debounce(function () {
      setTimeout(
        () => {
          this.getProposals();
        },
        1000,
      );
    }, 500),
  },
  filters: {
    formatToHuman,
    formatNum(num) {
      return helpers.numberToString(num);
    },
  },
};
